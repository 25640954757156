import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { Box, InputBase, Stack, IconButton } from '@mui/material';
import { AntSwitch } from 'atoms/AntSwitch';
import {
  StyledListActionButton,
  StyledListActionRow,
  StyledListFilterButton,
  StyledListFilterHeader,
  StyledListSwitchText,
} from 'organisms/Filter/forms/Form.styles';
import { FilterContext } from 'organisms/Filter/index';
import { array_move } from 'utils/helpers/array';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as UnCheckedIcon } from 'assets/icons/unchecked.svg';
import { ReactComponent as CheckedIcon } from 'assets/icons/checked.svg';
import loaderGif from 'assets/images/loaderGif.gif';

const ListFilter: FC<{ name: string }> = ({ name }) => {
  const context = useContext(FilterContext);
  const { options, filter, setFilter } = context;

  const [loading, setLoading] = useState<boolean>(false);
  const [selectedOnly, setSelectedOnly] = useState<boolean>(false);
  const [checked, setChecked] = useState<string[]>(
    (filter?.[name] as string[]) || []
  );
  const [search, setSearch] = useState<string>('');
  const [data, setData] = useState<{ id: string; name: string }[]>([]);
  const [list, setList] = useState<{ id: string; name: string }[]>([]);

  const showenItems: { id: string; name: string }[] = useMemo(() => {
    if (!selectedOnly) return list;
    return list.filter(({ id }) => checked.includes(id));
  }, [list, selectedOnly, checked]);

  const debouncedChangeHandler = useCallback(
    (event) => {
      const filtered = data.filter((o) =>
        o.name.toLowerCase().includes(event.target.value.toLowerCase())
      );

      setSearch(event.target.value);
      setList(filtered);
    },
    [checked, data]
  );

  const handleReset = useCallback(() => {
    setList(data);
    setSearch('');
    setChecked([]);
  }, [data]);

  const handleToggle = (id: string) => () => {
    if (id === 'select-all') {
      setChecked(list.map(({ id: itemId }) => itemId));
      return;
    }

    if (id === 'reset-all') {
      setChecked([]);
      return;
    }

    const currentIndex = checked.indexOf(id);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(id);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  useEffect(() => {
    setFilter({ ...filter, [name]: checked });
  }, [checked]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const func = options?.find((o) => o.code === name)?.fetchList;
        if (func) {
          const response = await func(name);
          setData(response);

          if (response.length === checked.length) {
            setList(response);
            return;
          }

          const sorted = [...response];
          checked.forEach((id) => {
            array_move(
              sorted,
              sorted.findIndex((o) => o.id === id),
              0
            );
          });
          setList(sorted);
        }
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if (!context) {
    throw new Error('ListFilter must be used within a FilterProvider');
  }

  return (
    <Stack>
      <StyledListFilterHeader $showResetButton={Boolean(search)}>
        <InputBase
          sx={{ flex: 1 }}
          value={search}
          placeholder="Search"
          inputProps={{ 'aria-label': 'search items' }}
          onChange={debouncedChangeHandler}
        />
        <IconButton
          sx={{ p: 0, ml: 1 }}
          onClick={handleReset}
          color="primary"
          aria-label="Reset search"
          component="span"
        >
          <CloseIcon />
        </IconButton>
      </StyledListFilterHeader>
      {loading ? (
        <Box sx={{ width: '100%', textAlign: 'center' }}>
          <img src={loaderGif} alt="Data loading" />
        </Box>
      ) : (
        <>
          {list.length > 3 && (
            <StyledListActionRow>
              <Stack>
                <StyledListActionButton
                  variant="text"
                  onClick={handleToggle('select-all')}
                >
                  Select All
                </StyledListActionButton>
                <StyledListActionButton
                  variant="text"
                  onClick={handleToggle('reset-all')}
                >
                  Reset
                </StyledListActionButton>
              </Stack>
              {list.length > 10 && (
                <Box>
                  <StyledListSwitchText>
                    Show only selected
                  </StyledListSwitchText>
                  <AntSwitch
                    checked={selectedOnly}
                    onChange={() => setSelectedOnly((prev) => !prev)}
                  />
                </Box>
              )}
            </StyledListActionRow>
          )}
          <List
            sx={{
              width: '100%',
              height: 300,
              overflowY: 'scroll',
              paddingTop: list.length > 3 ? 0 : '8px',
            }}
          >
            {showenItems.map(({ id, name: Name }) => {
              const labelId = `checkbox-list-label-${id}`;

              return (
                <ListItem key={id} disablePadding>
                  <StyledListFilterButton
                    dense
                    role={undefined}
                    onClick={handleToggle(id)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={checked.indexOf(id) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                        icon={<UnCheckedIcon />}
                        checkedIcon={<CheckedIcon />}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={Name} />
                  </StyledListFilterButton>
                </ListItem>
              );
            })}
          </List>
        </>
      )}
    </Stack>
  );
};

export default ListFilter;
