import React, { SyntheticEvent } from 'react';
import { Grid } from '@mui/material'; // , Stack, Tooltip
import Link from 'atoms/Link/Link';
import { StyledDetailsText } from 'molecules/Table/styles/Text.styles';
import {
  StyledDetailsContainer,
  StyledInvoiceApproveButton,
  StyledLinkButton,
} from 'organisms/CloudOpsReportAdmin/CloudOpsReportAdmin.styles';

import dayjs from 'dayjs';
import { neutrals } from 'utils/styles/color';
import { palette } from 'utils/styles/variables';
import { currencyFormatter } from 'utils/helpers/currencyFormatter';
import { getServiceName } from 'organisms/InvoicesTable/helpers';
import { CONTRACT_TYPE } from 'utils/constants/common';

import { Report } from 'organisms/CloudOpsReportAdmin/types';
import { formatHours, getInvoiceCreationDate } from 'utils/helpers/dates';

const getInvoiceInfo = (
  report: Report,
  handleApprove?: (e: SyntheticEvent<HTMLButtonElement>) => void
) => {
  if (report.invoice_number) {
    return `#${report.invoice_number}`;
  }

  if (report.error_state) {
    return 'Billing data incorrect';
  }

  const date = new Date();
  const currentYear = date.getFullYear();
  const currentMonth = date.getMonth() + 1;

  if (report.month === currentMonth && report.year === currentYear) {
    return `Current value ${
      currencyFormatter(Number(report.total_amount || 0), report.currency) || ''
    }`;
  }

  // https://sprints.zoho.com/workspace/zazmic#P71/itemdetails/I2208
  if (
    report.year <= currentYear &&
    report.month < currentMonth &&
    report.total_amount === 0
  ) {
    return 'Total amount is 0.0';
  }

  if (report.project.invoice_creation_approval_required) {
    if (
      !report.invoice_creation_approval &&
      report.project.contract_type === CONTRACT_TYPE.FIXED_PRICE &&
      handleApprove
    ) {
      return (
        <StyledInvoiceApproveButton
          variant="text"
          size="small"
          onClick={handleApprove}
          color="secondary"
        >
          Approve invoice creation
        </StyledInvoiceApproveButton>
      );
    }
    if (
      report.pm_approval &&
      report.project.contract_type !== CONTRACT_TYPE.FIXED_PRICE
    ) {
      if (!report.invoice_creation_approval && handleApprove) {
        return (
          <StyledInvoiceApproveButton
            variant="text"
            size="small"
            onClick={handleApprove}
            color="secondary"
          >
            Approve invoice creation
          </StyledInvoiceApproveButton>
        );
      }
      if (report.invoice_creation_approval && report.last_approval) {
        return 'Invoice will be created shortly';
      }
    }
    if (
      !report.pm_approval &&
      report.project.contract_type !== CONTRACT_TYPE.FIXED_PRICE
    ) {
      return 'Time logs not approved';
    }
    if (report.invoice_creation_approval) {
      return 'Invoice will be created shortly';
    }
  } else {
    if (report.project.contract_type !== CONTRACT_TYPE.FIXED_PRICE) {
      if (report.pm_approval) {
        if (report.last_approval) {
          const invoiceCreationDate = getInvoiceCreationDate(
            report.last_approval
          );
          return `Invoice generation on ${dayjs(invoiceCreationDate).format(
            'MMM D, YYYY [at] HH:mm'
          )}`;
        }
      } else {
        return 'Time logs not approved';
      }
    } else {
      return 'Invoice will be created shortly';
    }

    return '';
  }

  return '';
};

const getInvoiceColor = (
  report: Report,
  handleApprove?: (e: SyntheticEvent<HTMLButtonElement>) => void
) => {
  if (report.invoice_number) {
    return neutrals[700];
  }

  if (report.error_state) {
    return palette.error[450];
  }

  const date = new Date();
  const currentYear = date.getFullYear();
  const currentMonth = date.getMonth() + 1;

  if (report.month === currentMonth && report.year === currentYear) {
    return neutrals[700];
  }

  // https://sprints.zoho.com/workspace/zazmic#P71/itemdetails/I2208
  if (
    report.year <= currentYear &&
    report.month < currentMonth &&
    report.total_amount === 0
  ) {
    return neutrals[700];
  }

  if (report.project.invoice_creation_approval_required) {
    if (
      !report.invoice_creation_approval &&
      report.project.contract_type === CONTRACT_TYPE.FIXED_PRICE &&
      handleApprove
    ) {
      return neutrals[700];
    }
    if (
      report.pm_approval &&
      report.project.contract_type !== CONTRACT_TYPE.FIXED_PRICE
    ) {
      if (!report.invoice_creation_approval && handleApprove) {
        return neutrals[700];
      }
      if (report.invoice_creation_approval && report.last_approval) {
        return neutrals[700];
      }
    }
    if (
      !report.pm_approval &&
      report.project.contract_type !== CONTRACT_TYPE.FIXED_PRICE
    ) {
      return palette.error[450];
    }
    if (report.invoice_creation_approval) {
      return neutrals[700];
    }
  } else {
    if (report.project.contract_type !== CONTRACT_TYPE.FIXED_PRICE) {
      if (report.pm_approval) {
        if (report.last_approval) {
          return palette.success[600];
        }
      } else {
        return palette.error[450];
      }
    } else {
      return neutrals[700];
    }

    return neutrals[700];
  }

  return neutrals[700];
};

const renderRowDetailsLabelByType = (row: Report) => {
  const monthlyBudgetInHours = row.project.monthly_budget_in_hours || 0;
  if (row.project.contract_type === CONTRACT_TYPE.CLOUDOPS) {
    return (
      <StyledDetailsContainer>
        <StyledDetailsText $divider>Total hours logged</StyledDetailsText>
        <StyledDetailsText>
          Allocate {formatHours(monthlyBudgetInHours || 0)} h fee
        </StyledDetailsText>
        <StyledDetailsText $divider>
          Overage {formatHours(row.overage_hours || 0)} h (
          {currencyFormatter(
            Number(row.project.over_budget_hourly_rate),
            row.currency
          )}
          /h) fee
        </StyledDetailsText>
        <StyledDetailsText $bold>Total fee</StyledDetailsText>
      </StyledDetailsContainer>
    );
  }
  if (row.project.contract_type === CONTRACT_TYPE.FIXED_PRICE) {
    return (
      <StyledDetailsContainer sx={{ height: '100%', justifyContent: 'center' }}>
        <StyledDetailsText $divider>Fixed price fee</StyledDetailsText>
        <StyledDetailsText $bold>Total fee</StyledDetailsText>
      </StyledDetailsContainer>
    );
  }
  if (
    row.project.contract_type === CONTRACT_TYPE.TIME_AND_MATERIAL ||
    row.project.contract_type === CONTRACT_TYPE.PRORATED_FIXED_PRICE
  ) {
    return (
      <StyledDetailsContainer sx={{ height: '100%', justifyContent: 'center' }}>
        <StyledDetailsText $divider>Usage-based fee</StyledDetailsText>
        <StyledDetailsText $bold>Total fee</StyledDetailsText>
      </StyledDetailsContainer>
    );
  }
  return (
    <StyledDetailsContainer sx={{ height: '100%', justifyContent: 'center' }}>
      <StyledDetailsText>Usage-based fee</StyledDetailsText>
      <StyledDetailsText $divider>Fixed price fee</StyledDetailsText>
      <StyledDetailsText $bold>Total fee</StyledDetailsText>
    </StyledDetailsContainer>
  );
};

const renderRowDetailsValueByType = (row: Report) => {
  if (row.project.contract_type === CONTRACT_TYPE.CLOUDOPS) {
    return (
      <StyledDetailsContainer>
        <StyledDetailsText $divider>
          {formatHours(row.hours_tracked || 0)} h
        </StyledDetailsText>
        <StyledDetailsText>
          {currencyFormatter(Number(row.monthly_fee), row.currency)}
        </StyledDetailsText>
        <StyledDetailsText $divider>
          {currencyFormatter(Number(row.overage_amount || 0), row.currency)}
        </StyledDetailsText>
        <StyledDetailsText $bold>
          {currencyFormatter(Number(row.total_amount || 0), row.currency)}
        </StyledDetailsText>
      </StyledDetailsContainer>
    );
  }
  if (row.project.contract_type === CONTRACT_TYPE.FIXED_PRICE) {
    return (
      <StyledDetailsContainer sx={{ height: '100%', justifyContent: 'center' }}>
        <StyledDetailsText $divider>
          {currencyFormatter(Number(row?.monthly_fee || 0), row.currency)}
        </StyledDetailsText>
        <StyledDetailsText $bold>
          {currencyFormatter(Number(row?.total_amount || 0), row.currency)}
        </StyledDetailsText>
      </StyledDetailsContainer>
    );
  }
  if (
    row.project.contract_type === CONTRACT_TYPE.TIME_AND_MATERIAL ||
    row.project.contract_type === CONTRACT_TYPE.PRORATED_FIXED_PRICE
  ) {
    return (
      <StyledDetailsContainer sx={{ height: '100%', justifyContent: 'center' }}>
        <StyledDetailsText $divider>
          {currencyFormatter(
            Number(row.monthly_hourly_total_amount || 0),
            row.currency
          )}
        </StyledDetailsText>
        <StyledDetailsText $bold>
          {currencyFormatter(Number(row.total_amount || 0), row.currency)}
        </StyledDetailsText>
      </StyledDetailsContainer>
    );
  }
  return (
    <StyledDetailsContainer sx={{ height: '100%', justifyContent: 'center' }}>
      <StyledDetailsText>
        {currencyFormatter(
          Number(row?.monthly_hourly_total_amount || 0),
          row.currency
        )}
      </StyledDetailsText>
      <StyledDetailsText $divider>
        {currencyFormatter(Number(row?.monthly_fee || 0), row.currency)}
      </StyledDetailsText>
      <StyledDetailsText $bold>
        {currencyFormatter(Number(row.total_amount || 0), row.currency)}
      </StyledDetailsText>
    </StyledDetailsContainer>
  );
};

const contractWithUsageBasedFee = [
  CONTRACT_TYPE.PRORATED_FIXED_PRICE,
  CONTRACT_TYPE.TIME_AND_MATERIAL,
  CONTRACT_TYPE.MIXED_PRICE,
];

const renderRowDetails = (
  row: Report,
  link?: string,
  onShowLogs?: () => void
) => (
  <Grid container spacing={0} sx={{ p: '8px 80px', minHeight: '80px' }}>
    <Grid item xs>
      <StyledDetailsContainer $firstCol>
        {onShowLogs &&
          contractWithUsageBasedFee.includes(row.project.contract_type) && (
            <StyledLinkButton onClick={onShowLogs}>
              View time logs details
            </StyledLinkButton>
          )}
        {link && (
          <Link to={link} target="_blank" rel="noopener noreferrer">
            {`Open project ${getServiceName(link)}`}
          </Link>
        )}
      </StyledDetailsContainer>
    </Grid>
    <Grid item xs="auto">
      {renderRowDetailsLabelByType(row)}
    </Grid>
    <Grid item xs="auto" minWidth={120}>
      {renderRowDetailsValueByType(row)}
    </Grid>
  </Grid>
);

export { getInvoiceInfo, getInvoiceColor, renderRowDetails };
