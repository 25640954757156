import React from 'react';

import styled from 'styled-components';
import { Button, Stack, Typography } from '@mui/material';
import { colorsNeutrals, neutrals } from 'utils/styles/color';
import { StaticDateRangePicker } from '@mui/lab';
import ListItemButton from '@mui/material/ListItemButton';

// *******************************
// DATE RANGE PICKER FILTER STYLES
// *******************************
export const StyledDateRangeFilterHeader = styled(Stack)`
  && {
    flex-direction: row;
    padding: 12px 32px;
    justify-content: space-between;
    align-items: center;

    span {
      color: ${neutrals[700]};
      font-family: Poppins, sans-serif;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }

    .MuiButton-root {
      color: ${({ theme }) => theme.palette.primary.main};
      font-family: Poppins, sans-serif;
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      line-height: 15.5px;
      text-decoration-line: underline;

      padding: 0;
      min-width: unset;
    }
  }
`;

export const StyledDateRangeFilterAction = styled((props) => (
  <Typography component="span" {...props} />
))<{
  $active?: boolean;
}>`
  && {
    display: flex;
    height: 24px;
    padding: 0 16px;
    align-items: center;
    align-self: stretch;

    color: ${neutrals[700]};

    font-family: Poppins, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    cursor: pointer;

    ${({ $active }) =>
      $active &&
      `
        color: ${neutrals[500]};
        text-decoration-line: underline;
        &:before {
          content: '';
          position: absolute;
          left: 0;
          width: 3px;
          height: 10px;
          border-radius: var(--none, 0px) 1.5px 1.5px var(--none, 0px);
          background: ${neutrals[500]};
        }
      `}
  }
`;

export const StyledDateRangePicker = styled((props) => (
  <StaticDateRangePicker {...props} />
))(() => ({
  '& .PrivatePickersSlideTransition-root': {
    minHeight: '220px',
  },
  '.MuiDateRangePickerDay-rangeIntervalPreview': {
    borderColor: 'transparent !important',
  },
}));

// *******************************
// LIST FILTER STYLES
// *******************************
export const StyledListFilterHeader = styled(Stack)<{
  $showResetButton: boolean;
}>`
  && {
    flex-direction: row;
    padding: 0 16px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${neutrals[100]};
    height: 39px;

    .MuiInputBase-root {
      color: ${neutrals[700]};
      font-family: Poppins, sans-serif;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }

    .MuiButtonBase-root {
      opacity: ${({ $showResetButton }) => ($showResetButton ? 1 : 0)};
    }
  }
`;

export const StyledListFilterButton = styled(ListItemButton)`
  && {
    height: 32px;
    padding: 0 16px;
    gap: 16px;
    &:hover {
      background-color: transparent;
    }
    .MuiListItemIcon-root {
      min-width: unset;
    }
    .MuiCheckbox-root {
      padding: 0;
      margin-left: 0;
    }
    .MuiSvgIcon-root {
      color: ${neutrals[500]};
    }
    .MuiTypography-root {
      color: ${colorsNeutrals[700]};
      font-family: Poppins, sans-serif;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 108.333% */
    }
  }
`;

export const StyledListActionRow = styled(Stack)`
  && {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    height: 32px;

    & > div:first-of-type {
      display: flex;
      flex-direction: row;
      gap: 32px;
    }

    & > div:last-of-type {
      display: flex;
      flex-direction: row;
      gap: 8px;
    }
  }
`;

export const StyledListActionButton = styled(Button)`
  && {
    color: ${({ theme }) => theme.palette.primary.main};
    font-family: Poppins, sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 15.5px;
    text-decoration-line: underline;

    padding: 0;
    min-width: unset;

    &:hover {
      text-decoration-line: underline;
      background: transparent;
    }
  }
`;

export const StyledListSwitchText = styled(Typography)`
  && {
    font-family: Poppins, sans-serif;
    font-weight: 600;
    font-size: 10px;
    line-height: 15.5px;
    letter-spacing: 0%;
    color: ${neutrals[700]};

    margin: 0;
  }
`;
