export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const CONTRACT_TYPE = {
  CLOUDOPS: 'cloudops',
  FIXED_PRICE: 'fixed_price',
  TIME_AND_MATERIAL: 'time_and_material',
  PRORATED_FIXED_PRICE: 'prorated_fixed_price',
  MIXED_PRICE: 'mixed_pricing',
  MANUALLY_CALCULATED: 'manually_calc',
  NON_BILLABLE: 'non_billable',
  NOT_SET: 'not_set',
  QUICKBOOKS: 'quickbooks',
};

export const contractTypeMap = {
  [CONTRACT_TYPE.TIME_AND_MATERIAL]: 'Time and Material',
  [CONTRACT_TYPE.PRORATED_FIXED_PRICE]: 'Prorated fixed price',
  [CONTRACT_TYPE.MIXED_PRICE]: 'Mixed pricing',
  [CONTRACT_TYPE.FIXED_PRICE]: 'Fixed price',
  [CONTRACT_TYPE.CLOUDOPS]: 'CloudOps',
  [CONTRACT_TYPE.MANUALLY_CALCULATED]: 'Manually calculated',
  [CONTRACT_TYPE.NON_BILLABLE]: 'Non-billable',
  [CONTRACT_TYPE.NOT_SET]: 'Not set',
  [CONTRACT_TYPE.QUICKBOOKS]: 'Quickbooks',
};
