/* eslint-disable no-restricted-globals */
import React, { useMemo, useState, useEffect } from 'react';
import { Stack, Box, Typography } from '@mui/material';
import zazmicImage from 'assets/images/zazmic-man.png';
import SigninForm from '../../organisms/Forms/SigninForm/SigninForm';
import RequestForm from '../../organisms/Forms/RequestForm/RequestForm';
import CreatePasswordForm from '../../organisms/Forms/CreatePasswordForm/CreatePasswordForm';
import TemporaryPasswordForm from 'organisms/Forms/TemporaryPasswordForm';
import {
  AuthPageWrapper,
  ListBox,
  ImageWrapper,
  ZazmicConnectLogo,
  AuthTitleWrapper,
  GridItem,
  FormGrid,
  MobileAuthPageWrapper,
  MobileAuthFormSubheader,
  MobileImageWrapper,
} from './AuthPage.styles';
import ForgotForm from '../../organisms/Forms/ForgotForm/ForgotForm';
import VerificationForm from 'organisms/Forms/VerificationForm/VerificationForm';
import ContactUsForm from 'organisms/Forms/ContactUs/ContactUsForm';
import Footer from 'organisms/Footer/Footer';
import EmptyHeader from 'organisms/Header/EmptyHeader';
import zazmicConnectLogo from 'assets/images/zazmic-connect-logo.png';
import { ReactComponent as WelcomeIcon1 } from 'assets/images/welcome-icon-1.svg';
import { ReactComponent as WelcomeIcon2 } from 'assets/images/welcome-icon-2.svg';
import { ReactComponent as WelcomeIcon3 } from 'assets/images/welcome-icon-3.svg';

type AuthProps = {
  mode:
    | 'verification'
    | 'create-password'
    | 'request'
    | 'signin'
    | 'forgot'
    | 'temporary-password'
    | 'contact-us';
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    // localStorage.removeItem('pay');
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const AuthPage = ({ mode }: AuthProps) => {
  const { width, height } = useWindowDimensions();

  const auth = useMemo(() => {
    let values = {
      title: 'Hello!',
      subheader: 'Please enter your email and password',
      form: <SigninForm />,
    };
    if (mode === 'verification') {
      values = {
        title: 'Two-Factor Authentication',
        subheader: '',
        form: <VerificationForm />,
      };
    }
    if (mode === 'temporary-password') {
      values = {
        title: 'Set Password',
        subheader: '',
        form: <TemporaryPasswordForm />,
      };
    }
    if (mode === 'request') {
      values = {
        title: 'Request Account',
        subheader: '',
        form: <RequestForm />,
      };
    } else if (mode === 'create-password') {
      values = {
        title: 'Create Password',
        subheader: '',
        form: <CreatePasswordForm />,
      };
    } else if (mode === 'forgot') {
      values = {
        title: 'Forgot your password?',
        subheader:
          'Enter your email address below. We will email you a link to reset your password.',
        form: <ForgotForm />,
      };
    } else if (mode === 'contact-us') {
      values = {
        title: 'Contact us for support',
        subheader:
          'Enter your email and describe how we can assist you. Our team will get back to you as soon as possible.',
        form: <ContactUsForm />,
      };
    }
    return values;
  }, [mode]);

  if (width < 600) {
    return (
      <Stack direction="column" sx={{ height: '100vh' }}>
        <EmptyHeader />
        <MobileAuthPageWrapper sx={{ marginTop: '5vh' }}>
          {/* Auth Form */}
          <FormGrid item className="form-wrapper">
            {auth.subheader ? (
              <MobileAuthFormSubheader
                variant="body2"
                dangerouslySetInnerHTML={{ __html: auth.subheader }}
              />
            ) : (
              <div style={{ height: '20px' }} />
            )}
            {auth.form}
          </FormGrid>

          <MobileImageWrapper>
            <img src={zazmicImage} alt="Zazmic Man" />
          </MobileImageWrapper>
        </MobileAuthPageWrapper>

        <Footer />
      </Stack>
    );
  }

  return (
    <AuthPageWrapper container sx={{ height: `${height}px` }}>
      <GridItem
        item
        md={5}
        xs={12}
        padding={2}
        paddingTop="20vh"
        paddingX={[2, 8, 8, 14]}
      >
        <Stack>
          <AuthTitleWrapper>
            <Typography component="span">Welcome to</Typography>

            <ZazmicConnectLogo
              src={zazmicConnectLogo}
              alt="Zazmic-Connect logo"
            />
          </AuthTitleWrapper>

          <Box>
            <ListBox>
              <WelcomeIcon1 />
              <Typography variant="body1" color="#fff" fontWeight={500}>
                View invoices
              </Typography>
            </ListBox>

            <ListBox>
              <WelcomeIcon2 />
              <Typography variant="body1" color="#fff" fontWeight={500}>
                Pay invoices
              </Typography>
            </ListBox>

            <ListBox>
              <WelcomeIcon3 />
              <Typography variant="body1" color="#fff" fontWeight={500}>
                Set up auto payment for invoices
              </Typography>
            </ListBox>
          </Box>
        </Stack>

        <ImageWrapper>
          <img src={zazmicImage} alt="Zazmic Man" />
        </ImageWrapper>
      </GridItem>

      {/* Auth Form */}
      <FormGrid
        sx={{ height: `${window.innerHeight}px`, overflowY: 'auto' }}
        item
        md={7}
        padding={2}
        paddingX={[2, 8, 8, 14]}
        paddingTop="25vh"
        className="form-wrapper"
      >
        <Typography variant="h2" fontSize="2.5rem" fontWeight={800}>
          {auth.title}
        </Typography>
        <Typography
          variant="body2"
          color="#31363E"
          m="10px 0 34px"
          dangerouslySetInnerHTML={{ __html: auth.subheader }}
        />
        {auth.form}
      </FormGrid>
    </AuthPageWrapper>
  );
};

export default AuthPage;
