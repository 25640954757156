import React, { useState, useCallback, useEffect } from 'react';

import { HeadlessPageWrapper } from 'templates/PageWrapper/PageWrapper';
import { FilterAutocomplete } from 'atoms/TableFilter';
import { StyledPaper } from 'pages/ContactsPage/Contacts.styles';
import { StyledFilterWrapper } from 'molecules/Filter/Filter.styles';
import { Stack } from '@mui/material';

import axios, { AxiosResponse } from 'axios';
// import dayjs from 'dayjs';
import { camelCase, debounce, mapKeys } from 'lodash-es';
import ProjectsTable from 'organisms/ProjectsTable';
import {
  CloudOpsProjectsType,
  QuickbooksProjectType,
} from 'pages/CompanyPage/types';
import { projectInitialData } from 'pages/CompanyPage/helpers';
import CloudOpsProjectDetailsDialog from 'organisms/ProjectDialogs/CloudOpsProjectDetailsDialog';
import CloudOpsProjectSyncDialog from 'organisms/ProjectDialogs/CloudOpsProjectSyncDialog';
import ZohoProjectSyncDialog from 'organisms/ProjectDialogs/ZohoProjectSyncDialog';
import { currencyIcon } from 'utils/constants/invoiceStatus';
import { Company } from 'pages/CloudOpsReport/types';
import Filter from 'organisms/Filter';
import { IFilterOption } from 'organisms/Filter/types';
import { contractTypeMap } from 'utils/constants/common';

interface IParams {
  page: number;
  limit: number;
  search?: string;
  invoice_automation?: string;
  contract_type?: string;
  invoice_creation_approval?: string;
  // completion_date?: string;
}

export type filterApplyType = {
  invoice_automation: string;
  contract_type: string;
  invoice_creation_approval: string;
  // completion_date: string;
};

const fetchList = async (name: string) => {
  if (name === 'contract_type') {
    return Object.entries(contractTypeMap).map(([key, value]) => ({
      id: key,
      name: value,
    }));
  }
  if (name === 'invoice_automation' || name === 'invoice_creation_approval') {
    return [
      { id: 'On', name: 'ON' },
      { id: 'Off', name: 'OFF' },
    ];
  }

  return [] as { id: string; name: string }[];
};

const initialOptions: Array<IFilterOption> = [
  { code: 'contract_type', title: 'Contract type', fetchList, active: true },
  {
    code: 'invoice_automation',
    title: 'Invoicing automation',
    fetchList,
  },
  {
    code: 'invoice_creation_approval',
    title: 'Accountant approval',
    fetchList,
  },
  // { code: 'completion_date', title: 'Completion date' },
];

const ProjectsPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [multiFilter, setMultiFilter] = useState<Partial<filterApplyType>>({});
  const [search, setSearch] = useState<string | undefined>('');
  const [data, setData] = useState<{
    projects: CloudOpsProjectsType[];
    count: number;
  }>({ projects: [], count: 0 });

  const [company, setCompany] = useState<Company | undefined>();
  const [projectInfo, setProjectInfo] =
    useState<CloudOpsProjectsType>(projectInitialData);
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [showSyncDialog, setShowSyncDialog] = useState<boolean>(false);
  const [showQuickbookSyncDialog, setShowQuickbookSyncDialog] =
    useState<boolean>(false);
  const [showDetailsDialog, setShowDetailsDialog] = useState<boolean>(false);

  const handleFilterChange = useCallback(
    (nextFilterValue: { [key: string]: string[] }) => {
      const filterApply = Object?.entries(nextFilterValue).reduce(
        (acc, [key, value]) => {
          // if (key === 'completion_date' && value && value[0]) {
          //   acc.completion_date = dayjs(dayjs(value[0])).format('YYYY-MM-DD');
          // }

          if (key === 'contract_type' && value[0]) {
            acc.contract_type = value?.join(',');
          }

          if (key === 'invoice_automation' && value[0]) {
            acc.invoice_automation = value?.join(',');
          }

          if (key === 'invoice_creation_approval' && value[0]) {
            acc.invoice_creation_approval = value?.join(',');
          }

          return acc;
        },
        {} as filterApplyType
      );

      if (JSON.stringify(filterApply) === JSON.stringify(multiFilter)) return;

      setMultiFilter(filterApply);
    },
    [search, multiFilter]
  );

  /** debounce handler for two types: textfield and autocomplete */
  const debouncedChangeHandler = useCallback(
    debounce((event, value) => {
      setSearch(value || event?.target?.value || '');
    }, 1000),
    []
  );

  const fetchPageData = async (
    pagination: { page: number; limit: number },
    sort?: string
  ) => {
    try {
      setLoading(true);
      const { projects, count } = await fetchProjectsList(pagination, sort);

      setData({ projects: projects || [], count: count || 0 });
      return { projects: projects || [], count: count || 0 };
    } finally {
      setLoading(false);
    }
  };

  async function fetchProjectsList(
    pagination: { page: number; limit: number },
    sort?: string
  ) {
    const params: IParams = {
      ...prepareParams(),
      page: pagination.page || 1,
      limit: pagination.limit || 20,
    };

    const response: AxiosResponse<{
      projects: CloudOpsProjectsType[];
      count: number;
    }> = await axios.get(
      `${process.env.REACT_APP_USER_SERVICE}/projects?${
        sort || 'sort[name]=asc'
      }`,
      { params }
    );

    return {
      projects: response.data.projects || [],
      count: response.data.count || 0,
    };
  }

  function prepareParams() {
    return {
      ...(search?.trim() ? { search } : {}),
      ...(multiFilter || {}),
    };
  }

  const handleSettingsClick = useCallback(async (row: CloudOpsProjectsType) => {
    const companyResponse: AxiosResponse<Company> = await axios.get(`
        ${process.env.REACT_APP_USER_SERVICE}/company/${row.company_hs_id}
      `);
    setCompany(
      mapKeys(companyResponse.data, (v, k) => camelCase(k)) as Company
    );

    setProjectInfo(row as unknown as CloudOpsProjectsType);

    if (!row.id && row.quickbook_project_id) {
      setShowQuickbookSyncDialog(true);
    } else if (!row.id) {
      setShowSyncDialog(true);
    } else {
      setShowDetailsDialog(true);
    }
  }, []);

  const handleDetailsClose = useCallback(() => {
    setProjectInfo(projectInitialData); // clear selected project details
    setShowDetailsDialog(false);

    if (isUpdated) fetchPageData({ page: 1, limit: 20 });

    setIsUpdated(false);
  }, [isUpdated]);

  const handleUpdateModal = async () => {
    // const { projects } = await fetchPageData({ page: 1, limit: 20 });

    const { data: updatedProject }: AxiosResponse<CloudOpsProjectsType> =
      await axios.get(
        `${process.env.REACT_APP_USER_SERVICE}/projects/${projectInfo.id_str}`
      );
    // const updatedProject = projects.find(
    //   (item) => item.id_str === projectInfo.id_str
    // );

    if (updatedProject) {
      setProjectInfo({
        updatedProject,
        id_str: projectInfo.id_str,
      } as unknown as CloudOpsProjectsType);
    }
    setIsUpdated(true);
  };

  const handleCancelSyncDialog = useCallback(() => {
    setShowSyncDialog(false);
  }, []);

  const handleSubmitSyncDialog = useCallback(async () => {
    const { projects }: { projects: CloudOpsProjectsType[] } =
      await fetchPageData({ page: 1, limit: 20 });

    if (!projects.length) {
      setShowSyncDialog(false);
      return;
    }

    const { quickbook_project_id } = projectInfo;
    const prj = projects.find(
      (item) => item.quickbook_project_id === quickbook_project_id
    );

    if (!prj) {
      setShowSyncDialog(false);
      return;
    }

    const projectResponse = await axios.get(
      `${process.env.REACT_APP_USER_SERVICE}/projects/${prj.id}`
    );

    setProjectInfo(projectResponse.data);

    setShowSyncDialog(false);
    setShowQuickbookSyncDialog(false);

    setShowDetailsDialog(true);
  }, [projectInfo]);

  useEffect(() => {
    fetchPageData({ page: 1, limit: 20 });
  }, [search, multiFilter]);

  useEffect(() => {
    const currentId = document.body.getAttribute('id');
    if (showDetailsDialog || showSyncDialog || showQuickbookSyncDialog) {
      if (currentId === 'body') return;
      document.body.setAttribute('id', 'body');
    } else {
      document.body.setAttribute('id', '');
    }
  }, [showDetailsDialog, showSyncDialog, showQuickbookSyncDialog]);

  useEffect(
    () => () => {
      document.body.setAttribute('id', '');
    },
    []
  );

  return (
    <>
      <HeadlessPageWrapper isRelative>
        <StyledPaper sx={{ '.MuiCardContent-root': { p: '0 !important' } }}>
          <StyledFilterWrapper
            sx={{
              '.filter-popover-container': {
                top: '35px',
                left: '30px',
              },
            }}
          >
            <Stack direction="row" gap={3}>
              <Filter options={initialOptions} onChange={handleFilterChange} />

              <FilterAutocomplete
                isFreeSolo={!!search}
                value={search}
                options={[]}
                placeholder="Search by: Project name, Company name"
                tooltip="Search by: Project name, Company name"
                onChange={debouncedChangeHandler}
                onReset={() => setSearch('')}
              />
            </Stack>
          </StyledFilterWrapper>
          <ProjectsTable
            loading={loading}
            data={data}
            onChange={fetchPageData}
            onSettings={handleSettingsClick}
          />
        </StyledPaper>
      </HeadlessPageWrapper>

      {/* Project details dialog */}
      {showDetailsDialog && (
        <CloudOpsProjectDetailsDialog
          currency={company?.currency as keyof typeof currencyIcon}
          project={projectInfo}
          companyHsId={company?.companyHsId}
          onClose={handleDetailsClose}
          onUpdate={handleUpdateModal}
        />
      )}

      {/* Sync project dialog */}
      {showSyncDialog && (
        <CloudOpsProjectSyncDialog
          currency={company?.currency as keyof typeof currencyIcon}
          project={projectInfo as QuickbooksProjectType}
          companyHsId={company?.companyHsId}
          onClose={handleCancelSyncDialog}
          onSync={handleSubmitSyncDialog}
        />
      )}

      {/* Quickbook project sync dialog 1 */}
      {showQuickbookSyncDialog && (
        <ZohoProjectSyncDialog
          project={projectInfo}
          companyHsId={company?.companyHsId}
          onClose={() => setShowQuickbookSyncDialog(false)}
          onSync={handleSubmitSyncDialog}
        />
      )}
    </>
  );
};

export default ProjectsPage;
