import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  SyntheticEvent,
} from 'react';

import { Stack } from '@mui/material';
import PaginationActions from 'molecules/Table/TablePagination/Actions';
import Table from 'molecules/Table';
import Link from 'atoms/Link/Link';

import { StyledPagePaper } from 'organisms/CloudOpsReportAdmin/CloudOpsReportAdmin.styles';
import { StyledCellText } from 'molecules/Table/styles/Text.styles';
import { StyledTableContainer } from 'molecules/Table/styles';
import { StyledTablePagination } from 'molecules/Table/styles/Pagination.styles';

import axios from 'axios';
import { isAnyAdmin, SUPER_ADMIN } from 'utils/constants/roles';
import { useBaoSelector } from 'utils/hooks/redux';
import { contractTypeMap, months } from 'utils/constants/common';
import {
  getInvoiceColor,
  getInvoiceInfo,
  renderRowDetails,
} from 'organisms/CloudOpsReportAdmin/common';

import { ReportData, Report } from 'organisms/CloudOpsReportAdmin/types';
import ButtonMonthRangePicker from 'molecules/Calendar/ButtonMonthRangePicker';
import FilterDetails from 'molecules/Filter/FilterDetails';
import { StyledFilterWrapper } from 'molecules/Filter/Filter.styles';
import { IReportParams } from 'pages/CloudOpsReport/types';
import dayjs from 'dayjs';
import { FilterAutocomplete } from 'atoms/TableFilter';
import debounce from 'lodash-es/debounce';
import TimeLogTable from 'molecules/TimeLogTable';
import { ModalContent } from 'molecules/Modal/Modal.styles';
import Modal from 'molecules/Modal';
import { ApproveReport } from 'molecules/ApproveReport';
import { neutrals } from 'utils/styles/color';
import { palette } from 'utils/styles/variables';

const CloudOpsReportPage = () => {
  const [reports, setReports] = useState<ReportData>();
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);
  const [loader, setLoader] = useState(true);
  const [link, setLink] = useState<string>('');
  const [openModal, setOpenModal] = useState<boolean>(false);

  const [activeReport, setActiveReport] = useState<number>();
  const [invoiceApproveReport, setInvoiceApproveReport] =
    useState<Report | null>(null);
  const [search, setSearch] = useState<string | undefined>('');
  const [monthRange, setMonthRange] = useState<[null | Date, null | Date]>([
    null,
    null,
  ]);

  const { value: user } = useBaoSelector((state) => state.user);
  const isSuperAdmin = user.userType === SUPER_ADMIN;

  const milisecondsToHours = (miliseconds: number): number =>
    miliseconds / 1000 / 60 / 60;

  const reportDetails = useMemo(() => {
    const { count } = reports || {};
    return [{ label: 'Count', value: count ?? '0' }];
  }, [reports]);

  useEffect(() => {
    fetchPageData();
  }, [currentPage, rowsPerPage, monthRange, search]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setLoader(true);
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (option: number) => {
    setLoader(true);
    setCurrentPage(0);
    setRowsPerPage(option);
  };

  const handleApprove = (
    event: SyntheticEvent<HTMLButtonElement>,
    report: Report
  ) => {
    event.stopPropagation();
    setInvoiceApproveReport(report);
  };

  const columns = useMemo(() => {
    const returnValue = [
      {
        key: 'month',
        label: 'Month',
        sortable: false,
        width: '12%',
        render: ({ month, year }: Report) => (
          <StyledCellText $textType="bold">
            {months[month - 1]}, {year}
          </StyledCellText>
        ),
      },
      {
        key: 'company_name',
        label: 'Company Name',
        sortable: false,
        width: isAnyAdmin(user?.userType) ? '23%' : '37.5%',
        render: ({ company_hs_id, company_name }: Report) => (
          <Link to={`/company/${company_hs_id}`} $textDecoration="none">
            <StyledCellText $textType="link">{company_name}</StyledCellText>
          </Link>
        ),
      },
      {
        key: 'project',
        label: 'Project Name',
        sortable: false,
        width: isAnyAdmin(user?.userType) ? '25%' : '39.5%',
        render: ({ project }: Report) => (
          <StyledCellText $textType="default">{project.name}</StyledCellText>
        ),
      },
      {
        key: 'contract_type',
        label: 'Contract type',
        sortable: false,
        width: '15%',
        render: ({ project }: Report) => {
          const { contract_type } = project || {};

          return (
            <StyledCellText
              $textType="default"
              $color={contract_type ? neutrals[700] : palette.warning[600]}
            >
              {contract_type
                ? contractTypeMap[contract_type] || contract_type
                : 'Not set'}
            </StyledCellText>
          );
        },
      },
    ];

    if (isAnyAdmin(user?.userType)) {
      returnValue.push({
        key: 'invoice_number',
        label: 'Invoice details',
        sortable: false,
        width: '24%',
        render: (report: Report) => (
          <StyledCellText $color={getInvoiceColor(report)}>
            {getInvoiceInfo(
              report,
              isSuperAdmin ? (e) => handleApprove(e, report) : undefined
            )}
          </StyledCellText>
        ),
      });
    }

    return returnValue;
  }, [user?.userType]);

  const handleLogsOpen = useCallback(() => {
    setOpenModal(true);
    document.body.setAttribute('id', 'body');
  }, []);

  const handleLogsClose = useCallback(() => {
    setOpenModal(false);
    document.body.setAttribute('id', '');
  }, []);

  const rowDetailsRenderer = useCallback(
    (row: Report) => renderRowDetails(row, link, handleLogsOpen),
    [link]
  );

  const handleRowClick = useCallback(
    (event, row) => {
      if (window.getSelection()?.type === 'Range') return;

      if (activeReport === row.id) {
        setActiveReport(0);
        setLink('');
      } else {
        if (row.tasks_link) {
          axios
            .get(
              `${process.env.REACT_APP_USER_SERVICE}/company/${row.project.company_hs_id}`
            )
            .then((d) => {
              if (d.data.disable_access_to_zstream === false || isSuperAdmin) {
                setLink(row.tasks_link);
              }
            });
        }
        setActiveReport(row.id);
      }
    },
    [activeReport]
  );

  /** debounce handler for two types: textfield and autocomplete */
  const debouncedChangeHandler = useCallback(
    debounce((event, value) => {
      setSearch(value || event?.target?.value || '');
    }, 700),
    []
  );

  const onReset = useCallback(() => {
    setSearch('');
  }, []);

  const handleMonthRange = useCallback((value: [null | Date, null | Date]) => {
    setLoader(true);
    setMonthRange(value);
  }, []);

  const fetchPageData = async () => {
    try {
      const params: IReportParams = {
        limit: rowsPerPage,
        page: currentPage + 1,
      };

      if (monthRange[0] && monthRange[1]) {
        params.date_from = dayjs(monthRange[0]).format('YYYY-MM-DD');
        params.date_to = dayjs(monthRange[1]).format('YYYY-MM-DD');
      }

      if (search) {
        params.search = search;
      }

      const { data } = await axios.get<ReportData>(
        `${process.env.REACT_APP_USER_SERVICE}/projects/reports`,
        { params }
      );
      data.projects_reports = data.projects_reports.map((item) => {
        const monthly_budget_in_hours = item.project.monthly_budget_in_hours
          ? Math.round(item.project.monthly_budget_in_hours * 10) / 10
          : 0;

        let credit_balance = monthly_budget_in_hours
          ? monthly_budget_in_hours - milisecondsToHours(item.time_tracked)
          : 0;
        credit_balance = Math.round(credit_balance * 10) / 10;
        const minimalMonthlyBudget = item.project.minimum_monthly_budget
          ? item.project.minimum_monthly_budget
          : 0;
        const overBudgetHourlyRate = item.project.monthly_budget_in_hours
          ? item.project.monthly_budget_in_hours
          : 0;

        let curent_consumption = item.time_tracked ? item.time_tracked : 0;
        curent_consumption = milisecondsToHours(curent_consumption);
        const overConsumedHrs = curent_consumption - monthly_budget_in_hours;

        // number formats
        item.project.monthly_budget_in_hours = monthly_budget_in_hours;
        item.project.curent_consumption =
          Math.round(curent_consumption * 10) / 10;
        item.project.over_consumed_hrs =
          overConsumedHrs > 0 ? Math.round(overConsumedHrs * 10) / 10 : 0;

        item.project.credit_balance = Math.abs(credit_balance);
        let overBudget = 0;
        if (credit_balance < 0) {
          overBudget = Math.abs(credit_balance) * overBudgetHourlyRate;
        }
        item.project.overBudget = overBudget;
        item.project.total_fee = overBudget + minimalMonthlyBudget;
        return item;
      });
      setReports(data);
      setLoader(false);
    } catch (getReportError) {
      // console.log('Failed to get report!', getReportError);
    }
  };

  const activeReportData = reports?.projects_reports?.find(
    ({ id }) => id === activeReport
  );
  const shouldShowReportsDetails = (() => {
    if (reports && reports?.count <= 1) return false;

    return Boolean(monthRange[0] || monthRange[1] || search);
  })();

  return (
    <StyledPagePaper>
      <StyledFilterWrapper>
        <Stack direction="row" gap={3}>
          <ButtonMonthRangePicker
            tooltip="Filter by month"
            onChange={handleMonthRange}
          />

          <FilterAutocomplete
            isFreeSolo={!!search}
            value={search}
            options={[]}
            placeholder="Search by: Company name"
            tooltip="Filter by company"
            onChange={debouncedChangeHandler}
            onReset={onReset}
          />
        </Stack>

        <FilterDetails
          show={!loader && shouldShowReportsDetails}
          data={reportDetails}
        />
      </StyledFilterWrapper>
      <StyledTableContainer sx={{ minWidth: 1000 }}>
        <Table
          idKey="id"
          loading={loader}
          collapsible
          collapsedRow={activeReport}
          columns={columns}
          data={reports?.projects_reports || []}
          onRowClick={handleRowClick}
          rowDetailsRenderer={rowDetailsRenderer}
        />
        <StyledTablePagination
          labelDisplayedRows={() => null}
          rowsPerPageOptions={[]}
          count={reports?.count || 0}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onPageChange={handleChangePage}
          ActionsComponent={(props) => (
            <PaginationActions
              {...props}
              labelRowsPerPage="Items per page"
              rowsPerPageOptions={[20, 50, 100, 200]}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        />
      </StyledTableContainer>

      {invoiceApproveReport && (
        <ApproveReport
          report={invoiceApproveReport}
          onClose={() => setInvoiceApproveReport(null)}
          onAfterClose={() => {
            fetchPageData();
            setInvoiceApproveReport(null);
          }}
        />
      )}

      <Modal
        open={openModal}
        maxWidth="lg"
        title={`${
          activeReportData?.month ? months[activeReportData?.month - 1] : ''
        } time logs details`}
        subtitle={`This window provides comprehensive breakdown of time logs for the ${
          activeReportData?.month ? months[activeReportData?.month - 1] : ''
        }. The details include a list of users, their hourly rates, daily time logs with corresponding amounts, and the total logged hours and amount per user for the month. Additionally, you can download a CSV file containing this information for further analysis or record-keeping.`}
        onClose={handleLogsClose}
        sx={{ zIndex: 33333 }}
      >
        <ModalContent
          sx={{
            paddingBottom: '16px !important',
            maxHeight: '400px',
            overflow: 'hidden',
          }}
        >
          {activeReportData ? (
            <TimeLogTable report={activeReportData} />
          ) : (
            '...'
          )}
        </ModalContent>
      </Modal>
    </StyledPagePaper>
  );
};

export default CloudOpsReportPage;
