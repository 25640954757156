import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Box } from '@mui/material';
import axios from 'axios';
import { PaginationWrapper } from 'organisms/InvoicesTable/InvoicesTable.styles';
import {
  HeadlessPageWrapper,
  MobilePageWrapper,
} from 'templates/PageWrapper/PageWrapper';
import loaderGif from 'assets/images/loaderGif.gif';
import { useBaoSelector } from 'utils/hooks/redux';
import { SUPER_ADMIN } from 'utils/constants/roles';
import { useWindowDimensions } from 'pages/AuthPage/AuthPage';
import { MobileInvoicesTitle } from 'utils/styles/Typography.styles';
import { MobileCloudOpsReports } from 'molecules/MobileCloudOpsReports/MobileCloudOpsReports';
import { MobileCloudOpsDetail } from 'molecules/MobileCloudOpsReports/MobileCloudOpsDetail';
import {
  ReportData,
  Report,
  Company,
  IReportParams,
} from 'pages/CloudOpsReport/types';
import { months } from 'utils/constants/common';
import { StyledCellText } from 'molecules/Table/styles/Text.styles';
import Table from 'molecules/Table';
import { renderRowDetails } from 'organisms/CloudOpsReportAdmin/common';
import PaginationActions from 'molecules/Table/TablePagination/Actions';
import { StyledTableContainer } from 'molecules/Table/styles';
import { StyledTablePagination } from 'molecules/Table/styles/Pagination.styles';
import { StyledPagePaper } from 'organisms/CloudOpsReportAdmin/CloudOpsReportAdmin.styles';
import { StyledFilterWrapper } from 'molecules/Filter/Filter.styles';
import ButtonMonthRangePicker from 'molecules/Calendar/ButtonMonthRangePicker';
import dayjs from 'dayjs';
import FilterDetails from 'molecules/Filter/FilterDetails';

const CloudOpsReportPage = () => {
  const { width } = useWindowDimensions();

  const [reports, setReports] = useState<ReportData>();
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);
  const [loader, setLoader] = useState(true);
  const [link, setLink] = useState<string>('');
  const [company, setCompany] = useState<Company>({
    address: '',
    name: '',
    email: '',
    domain: '',
    gcpBillingId: '',
    companyHsId: '',
    lifetime_billings: 0,
    since: '',
    past_due: '',
    auto_pay: false,
    total_due: 0,
    discount: 0,
    createSoftwareDevInvoices: false,
    currency: '',
  });
  const [activeReport, setActiveReport] = useState<number>();
  const [selectedReportId, setSelectedReportId] = useState<number | null>(null);
  const [monthRange, setMonthRange] = useState<[null | Date, null | Date]>([
    null,
    null,
  ]);

  const { value: user } = useBaoSelector((state) => state.user);
  const { currentCompany } = useBaoSelector((state) => state.common);
  const hsId = currentCompany?.company_hs_id
    ? String(currentCompany?.company_hs_id)
    : null;

  const isSuperAdmin = user.userType === SUPER_ADMIN;

  const milisecondsToHours = (miliseconds: number) =>
    miliseconds / 1000 / 60 / 60;

  const reportDetails = useMemo(() => {
    const { count } = reports || {};
    return [{ label: 'Count', value: count ?? '0' }];
  }, [reports]);

  useEffect(() => {
    (async () => {
      if (!hsId) return;

      try {
        const params: IReportParams = {
          company_hs_id: hsId,
          limit: rowsPerPage,
          page: currentPage + 1,
        };

        if (monthRange[0] && monthRange[1]) {
          params.date_from = dayjs(monthRange[0]).format('YYYY-MM-DD');
          params.date_to = dayjs(monthRange[1]).format('YYYY-MM-DD');
        }

        const { data } = await axios.get<ReportData>(
          `${process.env.REACT_APP_USER_SERVICE}/projects/reports`,
          { params }
        );
        data.projects_reports = data.projects_reports.map((item) => {
          const monthly_budget_in_hours = item.project.monthly_budget_in_hours
            ? Math.round(item.project.monthly_budget_in_hours * 10) / 10
            : 0;

          let credit_balance = monthly_budget_in_hours
            ? monthly_budget_in_hours - milisecondsToHours(item.time_tracked)
            : 0;
          credit_balance = Math.round(credit_balance * 10) / 10;
          const minimalMonthlyBudget = item.project.minimum_monthly_budget
            ? item.project.minimum_monthly_budget
            : 0;
          const overBudgetHourlyRate = item.project.monthly_budget_in_hours
            ? item.project.monthly_budget_in_hours
            : 0;

          let curent_consumption = item.time_tracked ? item.time_tracked : 0;
          curent_consumption = milisecondsToHours(curent_consumption);

          const overConsumedHrs = curent_consumption - monthly_budget_in_hours;
          // number formats
          item.project.monthly_budget_in_hours = monthly_budget_in_hours;
          item.project.curent_consumption =
            Math.round(curent_consumption * 10) / 10;
          item.project.over_consumed_hrs =
            overConsumedHrs > 0 ? Math.round(overConsumedHrs * 10) / 10 : 0;

          item.project.credit_balance = credit_balance;
          let overBudget = 0;
          if (credit_balance < 0) {
            overBudget = Math.abs(credit_balance) * overBudgetHourlyRate;
          }
          item.project.overBudget = overBudget;
          item.project.total_fee = overBudget + minimalMonthlyBudget;
          return item;
        });
        setReports(data);
        setLoader(false);
      } catch (getReportError) {
        // console.log('Failed to get report!', getReportError);
      }
    })();

    (async () => {
      try {
        if (hsId) {
          const { data } = await axios.get<Company>(
            `${process.env.REACT_APP_USER_SERVICE}/company/${hsId}`
          );

          setCompany(data);
        }
      } catch (getCompanyError) {
        // console.log('Failed to get company!', getCompanyError);
      }
    })();
  }, [hsId, currentPage, rowsPerPage, monthRange]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setLoader(true);
    setCurrentPage(newPage);
  };

  const handleMonthRange = (value: [null | Date, null | Date]) => {
    setLoader(true);
    setMonthRange(value);
  };

  const handleChangeRowsPerPage = (option: number) => {
    setLoader(true);
    setCurrentPage(0);
    setRowsPerPage(option);
  };

  const handleRowClick = useCallback(
    (event, row) => {
      if (activeReport === row.id) {
        setActiveReport(0);
        setLink('');
      } else {
        if (row.tasks_link) {
          axios
            .get(
              `${process.env.REACT_APP_USER_SERVICE}/company/${row.project.company_hs_id}`
            )
            .then((d) => {
              if (d.data.disable_access_to_zstream === false || isSuperAdmin) {
                setLink(row.tasks_link);
              }
            });
        }
        setActiveReport(row.id);
      }
    },
    [activeReport]
  );

  const handleOpenDetails = useCallback(
    (report: Report) => {
      if (report.tasks_link) {
        axios
          .get(
            `${process.env.REACT_APP_USER_SERVICE}/company/${report.project.company_hs_id}`
          )
          .then((d) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (d.data.disable_access_to_zstream === false || isSuperAdmin) {
              setLink(report.tasks_link);
            }
          });
      }
      setSelectedReportId(report.id);
    },
    [reports]
  );

  const handleCloseDetails = useCallback(() => {
    setSelectedReportId(null);
    setLink('');
  }, [reports]);

  const columns = useMemo(
    () => [
      {
        key: 'month',
        label: 'Month',
        sortable: false,
        width: '20%',
        render: ({ month, year }: Report) => (
          <StyledCellText $textType="bold">
            {months[month - 1]}, {year}
          </StyledCellText>
        ),
      },
      {
        key: 'project',
        label: 'Project Name',
        sortable: false,
        width: '50%',
        render: ({ project }: Report) => (
          <StyledCellText $textType="default">{project.name}</StyledCellText>
        ),
      },
      {
        key: 'invoice_number',
        label: 'Invoice #',
        sortable: false,
        width: '30%',
        render: (report: Report) => (
          <StyledCellText>
            {report.invoice_number ? `#${report.invoice_number}` : ''}
          </StyledCellText>
        ),
      },
    ],
    [activeReport]
  );

  if (width < 600 && !isSuperAdmin) {
    if (selectedReportId !== null) {
      const selectedReport = reports?.projects_reports.find(
        ({ id }) => id === selectedReportId
      );
      if (selectedReport) {
        return (
          <MobilePageWrapper
            title={
              <MobileInvoicesTitle variant="h2" my={3}>
                CloudOps
              </MobileInvoicesTitle>
            }
          >
            <MobileCloudOpsDetail
              link={link}
              data={selectedReport}
              onBack={handleCloseDetails}
              companyName={company?.name}
            />
          </MobilePageWrapper>
        );
      }
    }

    return (
      <MobilePageWrapper
        title={
          <MobileInvoicesTitle variant="h2" my={3}>
            CloudOps
          </MobileInvoicesTitle>
        }
      >
        {loader ? (
          <Box sx={{ textAlign: 'center', padding: '40px 0' }}>
            <img src={loaderGif} alt="loader" />
          </Box>
        ) : (
          <>
            <MobileCloudOpsReports
              data={reports}
              onDetails={handleOpenDetails}
            />
            <PaginationWrapper
              style={{ borderRadius: '4px', backgroundColor: '#ffffff' }}
            >
              <StyledTablePagination
                labelDisplayedRows={() => null}
                rowsPerPageOptions={[]}
                count={reports?.count || 0}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onPageChange={handleChangePage}
                ActionsComponent={(props) => (
                  <PaginationActions
                    {...props}
                    labelRowsPerPage="Items per page"
                    rowsPerPageOptions={[20, 50, 100, 200]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                )}
              />
            </PaginationWrapper>
          </>
        )}
      </MobilePageWrapper>
    );
  }

  const shouldShowReportsDetails = (() =>
    !(reports?.count === 1 || !monthRange[0] || !monthRange[1]))();

  return (
    <HeadlessPageWrapper>
      <StyledPagePaper>
        <StyledFilterWrapper>
          <ButtonMonthRangePicker
            tooltip="Filter by month"
            onChange={handleMonthRange}
          />

          <FilterDetails
            show={!loader && shouldShowReportsDetails}
            data={reportDetails}
          />
        </StyledFilterWrapper>
        <StyledTableContainer style={{ minWidth: 800 }}>
          <Table
            idKey="id"
            loading={loader}
            collapsible
            collapsedRow={activeReport}
            columns={columns}
            data={reports?.projects_reports || []}
            onRowClick={handleRowClick}
            rowDetailsRenderer={renderRowDetails}
          />
          <StyledTablePagination
            labelDisplayedRows={() => null}
            rowsPerPageOptions={[]}
            count={reports?.count || 0}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onPageChange={handleChangePage}
            ActionsComponent={(props) => (
              <PaginationActions
                {...props}
                labelRowsPerPage="Items per page"
                rowsPerPageOptions={[20, 50, 100, 200]}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          />
        </StyledTableContainer>
      </StyledPagePaper>
    </HeadlessPageWrapper>
  );
};

export default CloudOpsReportPage;
